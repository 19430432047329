import { defineStore } from "pinia"

export const useUserStore = defineStore("UserStore", {
	state() {
		return {
			rerender: 0,
			user: {},
			team: [],
			tags: [],
			prefs: [],
			token: null,
			mykey: null,
			stripe: {},
			forceLogout: false,
		}
	},
	getters: {
		abo: (state) => (state.user?.abo ? state.user.abo : null),
		language: (state) => state.user?.language ? state.user.language : "en",
		isAuthenticated: (state) => (state.token && state.mykey ? true : false),
		pdfUser(state) {
			const user = {
				account: state.user.account,
				token: state.token,
				language: state.language,
				brandcolor: state.prefs.brandcolor || '#5294e0',
				brandcurrency: state.prefs.brandcurrency || '€',
				brandformat: state.prefs.brandformat || 'A4',
			}
			return user
		},
		getDisplayName: (state) => (id) => {
			if (!id) return ""
			const member = state.team.filter((m) => m.id == id)[0]
			if (member?.displayname) return member.displayname
			if (member?.nachname)
				return `${member.vorname} ${member.nachname}`.trim()
			return member.email
		},
		expired(state) {
			const heute = new Date().toISOString().split("T")[0]
			if (state.user?.bis < heute) return true
			return false
		},
		isLocalhost() {
			const baseurl = import.meta.env.VITE_APP_API_BASE_URL
			if (baseurl.includes("localhost")) return true
			return false
		},
		managers(state) {
			return state.team.filter((t) => t.level > 5)
		},
		contactTags(state) {
			const filteredData = state.tags.filter((t) => t.modul === 'contact')
			return filteredData.map(item => item.tag).sort();
		},
		dokuTags(state) {
			const filteredData = state.tags.filter((t) => t.modul === 'doku')
			return filteredData.map(item => item.tag).sort();
		},
	},
	actions: {
		setUser(r) {
			this.token = r.token
			this.user = r.user
			this.team = r.team
			this.prefs = r.prefs
			this.tags = r.tags
		},
		setKey(r) {
			this.mykey = r
		},
		setPublicAccount(id) {
			this.user = { account: id }
		},
		cancelAbo(canceled_at) {
			this.user.canceled_at = canceled_at
		},
		expireUser() {
			this.user.abo = "expired"
			this.user.plan = 0
		},
		setProfile(profile) {
			this.user.vorname = profile.vorname
			this.user.nachname = profile.nachname
			this.user.displayname = profile.displayname
			this.user.language = profile.language
		},
		upgradeAccount(payload) {
			this.user.abo = payload.abo
			this.user.plan = payload.plan
			this.user.bis = payload.bis
		},
		savePrefs(prefs) {
			this.prefs = prefs
		},
		// member
		addMember(member) {
			this.team.push(member)
		},
		saveMember(member) {
			const i = this.team.findIndex((x) => x.id === member.id)
			this.team[i].art = member.art
			this.team[i].role = member.role
		},
		removeMember(id) {
			const i = this.team.findIndex((x) => x.id === id)
			this.team.splice(i, 1)
		},
		addTag(tag) {
			this.tags.push(tag)
		},
	},
	persist: true,
})
