export const ABO = Object.freeze({
	STARTER: 1, // (adressen + invoices + reports)
	PRO: 3, // (plus doku + letters)
	TEAM: 5, // (plus team + todos + branding)
	STUDIO: 7, // (plus einkauf + timeline + prozesse + wiki)
	TRIAL: 10, //
})

export const LEVEL = Object.freeze({
	CONTRIBUTOR: 3, // (dokus + prozesse + todos)
	STAFF: 5, // (edit adressen, briefe, dokus, druck, prozesse, wiki)
	MANAGER: 7, // (edit alles, v.a. auch jobs, exkl. account)
	ADMIN: 8, // (edit auch account)
})
