import "@/assets/styles/tailwind.scss";
import '@fontsource-variable/overpass';

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
const store = createPinia();
store.use(piniaPluginPersistedstate);

import router from "@/router.js";
import i18n from "./i18n.js";

import { createHead } from "@vueuse/head";
const head = createHead();

import { plugin, defaultConfig } from "@formkit/vue";
import { de, en } from "@formkit/i18n";

import { createApp } from "vue";
import App from "@/App";

// import VueCreditCardValidation from "vue-credit-card-validation";

// import { H } from "highlight.run";
// H.init("jdk89rd5", {
// 	environment: "production",
// 	version: "3.0.1",
// 	networkRecording: {
// 		enabled: true,
// 		recordHeadersAndBody: true,
// 		urlBlocklist: ["http://localhost"],
// 	},
// });

const app = createApp(App);
app.use(router)
	.use(store)
	.use(i18n)
	.use(head)
	.use(
		plugin,
		defaultConfig({
			locales: { de, en },
			locale: "en",
		}),
	)
	.mount("#app");
