<script setup>
import { computed } from 'vue'
import { useUserStore } from "@/stores/user.js"
import useFiles from '@/composables/files'

const userStore = useUserStore()
const { makeAvatarPath } = useFiles()

const props = defineProps({
	userId: { type: Number, default: 0 },
	isContact: { type: Boolean, default: false },
	size: { type: String, default: 'mid' },
	trigger: { type: Number, default: 0 },
});

const imgPath = computed(() => {
	return makeAvatarPath(props.userId || userStore.user.id, props.isContact, props.trigger)
})

</script>

<template>
	<div>
		<img :src="imgPath" :alt="userStore.user.displayname" :class="size" />
	</div>
</template>

<style lang="sass" scoped>
div
	@apply inline-block rounded-full overflow-hidden
	img
		@apply object-cover
		&.biggest // im profil
			@apply w-20 h-20
		&.big // in den doku-cards, adressen und teamliste ??
			@apply w-10 h-10
		&.mid // kleiner im seitlichen mainmenu unten
			@apply w-8 h-8
		&.small // im team-aside
			@apply w-6 h-6
			min-width: 24px
			min-height: 24px
</style>
