<script setup>
import { useAppStore } from '@/stores/app.js'
const appStore = useAppStore()
</script>

<template>
	<div v-if="appStore.isFetching" class="bckgrd">
		<div class="loader"></div>
	</div>
</template>

<style lang="sass" scoped>
div.bckgrd
	@apply fixed top-0 left-0 w-full h-full z-50 bg-white opacity-60 flex flex-wrap justify-center items-center
	.loader
		width: 60px
		height: 60px
		background-size: contain
		background-position: center center
		background-repeat: no-repeat
		background-image: url(@/assets/svg/gear-filled.svg)
		animation: dreh 2s ease-in-out 0s normal infinite
		svg
			width: 100%
			height: 100%

@keyframes dreh
	from
		transform: rotate(0)
	to
		transform: rotate(360deg)
</style>
