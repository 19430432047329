<template>
	<MainMenu v-if="userStore.isAuthenticated" />
	<Notification v-if="userStore.isAuthenticated" />
	<router-view />
	<Loader />
</template>

<script setup>
import { onMounted, watchEffect, computed } from "vue"
import { useTitle } from "@vueuse/core"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/user.js"
import { useI18n } from 'vue-i18n'
import i18n from "@/i18n.js"
import Notification from '@/components/_elements/Notification'
import MainMenu from "@/components/mainmenu"
import Loader from "@/components/loader"

const { t } = useI18n()

const userStore = useUserStore()
onMounted(() => {
	if (userStore.isAuthenticated)
		i18n.global.locale.value =
			userStore.user.language?.substring(0, 2) || 'en'
	else i18n.global.locale.value = navigator.language.substring(0, 2)
})

const route = useRoute()
const title = useTitle(`werkherz`)
const localTitle = computed(() => {
	if(route.fullPath.includes('/account')) return t(`account.${route.meta.mm}`)
	return t('pagetitle.'+route.meta.mm.toLowerCase())
})
watchEffect(async () => {
	title.value = route?.meta.mm ? `${localTitle.value} ♡ werkherz` : `werkherz`
})

const router = useRouter()
watchEffect(async () => {
	if(userStore.forceLogout) {
		userStore.forceLogout = false
		router.replace('/login')
	}
})
</script>

<style lang="sass">
body
	@apply bg-white
	font-family: var(--family)
	font-weight: var(--mager)
	color: var(--text)
	min-width: 1200px
	min-height: 100vh

h1, h2, h3, h4, h5, h6
	@apply py-8 text-lg text-left px-0 m-0
	color: var(--blau)
	font-weight: var(--mager)
#welcome
	h1, h2, h3, h4, h5, h6
		@apply text-center
main
	margin-left: calc(var(--mmschmal) *2)
	padding-bottom: var(--mmschmal)
section
	@apply my-8

label
	@apply text-xs
	color: var(--labels)

input, textarea
	@apply appearance-none rounded w-full py-1 px-2 bg-white placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:border-transparent
	color: var(--text)
	border: 1px solid var(--hellgrau)
	&:invalid,
	&.error
		outline: 1px solid var(--rot)
		color: var(--rot)
input[type=color]
	max-width: 4ch
input[type=date]
	width: 150px
	max-width: 150px
input[type=time]
	max-width: 10ch
input[type=number]
	@apply text-right
	max-width: 10ch
	-moz-appearance: textfield
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button
		-webkit-appearance: none

select
	@apply block py-1 px-2 bg-white placeholder-gray-400 text-lg focus:outline-none
	border: 1px solid var(--hellgrau)
	max-width: 100%

ul,ol
	@apply p-0 m-0

strong
	font-weight: 700

form.standard
	ul
		li
			@apply grid items-start py-1
			grid-template-columns: 100px 1fr
			label
				@apply block text-xs
				color: var(--labels)
				a
					color: var(--orange)
			input
				@apply block w-full
				color: var(--text)
				&:invalid,
				&.error
					outline: 1px solid var(--rot)
					color: var(--rot)
.ql-editor
	@apply text-base
	padding: 0 !important
	font-family: var(--family)
	font-weight: var(--mager)
	border-bottom: 1px solid var(--hellgrau)

.help
	@apply text-sm italic mb-4
	color: var(--grau)
	line-height: 130%

.error
	color: var(--rot)


// welcome form
form.formkit-form
	p.error
		@apply text-xs text-red-500 m-0 pt-2
	ul.formkit-messages
		@apply text-xs text-red-500 m-0 p-0
		li
			padding-block: 4px !important
			background-color: transparent !important


// filter vom aside
ul.search-form.search-form
	@apply block
	& > li
		@apply py-1
		label
			@apply block text-white opacity-50
		input, select, textarea
			@apply w-full bg-white opacity-75 focus:opacity-100
			&[type=checkbox]
				@apply w-7 h-7
		&:last-child
			@apply mt-4 flex flex-row justify-between
			a
				@apply block text-white opacity-75 hover:opacity-100 cursor-pointer
				svg
					@apply w-8 h-8
					fill: var(--hellblau)
					stroke: var(--blau)
			button
				@apply text-sm rounded-full px-4 py-1 text-white opacity-75 hover:opacity-100
				background-color: var(--blaua)
				&:disabled
					cursor: not-allowed


.modal-enter-active, .modal-leave-active
	transition: all 250ms ease
.modal-enter-from, .modal-leave-to
	transform: translateY(50px)
	opacity: 0

@media print
	header, footer, nav, aside, .head
		display: none
</style>
