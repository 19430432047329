import { createI18n } from "vue-i18n";
import de from "@/locales/de.json";
import en from "@/locales/en.json";

const i18n = createI18n({
	legacy: false,
	locale: "en",
	fallbackLocale: "en",
	messages: {
		en,
		de,
	},
	numberFormats: {
		en: {
			currency: {
				style: "currency",
				currency: "USD",
			},
		},
		de: {
			currency: {
				style: "currency",
				currency: "EUR",
			},
		},
	},
	datetimeFormats: {
		en: {
			short: {
				month: "short",
			},
			long: {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			},
		},
		de: {
			short: {
				month: "short",
			},
			long: {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			},
		},
	},
});

export default i18n;
