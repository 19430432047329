import { useUserStore } from '@/stores/user.js';
import { useItemStore } from '@/stores/item.js'

export default function useFiles() {
	const returnFullPath = (parts) => {
		return `${import.meta.env.VITE_APP_API_BASE_URL}/media/` + parts.join('/')
	}

	return {
		makeAvatarPath: (id, isContact, trigger) => {
			if(!id) return ''
			if(isContact) id = `k${id}`
			const userStore = useUserStore()
			return returnFullPath(['avatar', userStore.user.account, id, trigger])
		},
		makeResourcePath: (name) => {
			if(!name) return ''
			const userStore = useUserStore()
			const itemStore = useItemStore()
			return returnFullPath(['resource', userStore.user.account, itemStore.item.id, name])
		},
		checkFile: (fileSize, fileName, limit) => {
			if (fileSize > limit) return 'size'
			if (!/\.(jpe?g|png|gif|webp)$/i.test(fileName)) return 'format'
			return true
		},
		download: (arr, filename) => {
			const csvString = arrayToCsv(arr)
			const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
			const url = URL.createObjectURL(blob)
			const el = document.createElement('a')
			el.href = url
			el.style.display = 'none'
			el.setAttribute('download', `${filename}.csv`)
			document.body.appendChild(el)
			el.click()
			document.body.removeChild(el)
		},
	};
}

