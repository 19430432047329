<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores/app.js';

const appStore = useAppStore()

const message = computed(() => {
	if( appStore.message ) return appStore.message
	return ''
})

const classes = computed(() => {
	if(appStore.showNotification || appStore.message) return 'shown'
	return ''
})
</script>

<template>
	<div class="notification" :class="classes">
		{{ message }}
	</div>
</template>

<style lang="sass" scoped>
div.notification
	@apply fixed transition-all rounded text-white z-10 px-6 py-2
	top: var(--padding)
	right: var(--padding)
	background-color: var(--rot)
	transform: translateX(150%)
	&.shown
		transform: translateX(0)
</style>
