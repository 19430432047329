import { defineStore } from 'pinia'

export const useItemStore = defineStore('ItemStore', {
	state: () => {
		return {
			item: null, // main data von prozess, job, doku, procurement, wiki
			subitems: null, // positionen/notes für jobs und procurement und dokus
			items2delete: null, // [ids] von positionen, die gelöscht werden sollen
			tableItem: null, // für jobs- und dokus-liste
			settings: null, // copy of item/main für die bearbeitung
			child: null, // für die mit dem dotmenu zu verändernden data table rows
			dokus: null, // zum editieren von todos
			dokuFilter: '' // zum filtern der notes über das summen-aside
		}
	},
	getters: {
		monate: (state) => { // für doku details
			if(!state.subitems) return []
			if(!state.subitems[0]?.jahrmonat) return []
			const allJahrmonate = state.subitems.map((item) => item.jahrmonat)
			const uniqueJahrmonate = [...new Set(allJahrmonate)]
			return uniqueJahrmonate.sort((a, b) => a > b ? -1 : 1)
		},
		mainTitle: (state) => { // für doku details
			if(!state.item) return ''
			return state.item?.kunde ? `${state.item?.kunde} ${state.item.titel}` : state.item.titel
		},
		dokuTasks: (state) => { // für todos
			return (id) => state.subitems.filter((item) => item.doku_id === id)
		},
	},
	actions: {
		setItem(payload) {
			this.$reset()
			this.item = {...payload}
			this.settings = {...payload}
		},
		setSettings() {
			this.item = {...this.settings}
		},
		setSubItems(payload) {
			this.subitems = payload
			this.items2delete = []
		},
		setDokus(payload) {
			this.dokus = payload
		},
		deleteSubItem(id) { // nur für job positionen
			this.items2delete.push(id)
			const idx = this.subitems.findIndex((item) => item.id === id)
			this.subitems.splice(idx, 1)
		},
		setChild(payload) {
			this.child = {...payload}
		},
		resetChild() {
			this.child = {}
		},
	},
})
