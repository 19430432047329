<script setup>
import { computed } from 'vue'
import { ABO, LEVEL } from "@/constants.js"
import { useUserStore } from "@/stores/user.js"
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Avatar from "@/components/_elements/Avatar.vue"
import invers from "@/assets/svg/invers.vue"
import BriefIcon from "@/assets/svg/briefe.vue"
import ContactIcon from "@/assets/svg/contacts.vue"
import DokuIcon from "@/assets/svg/dokus.vue"
import DruckIcon from "@/assets/svg/druck.vue"
import JobIcon from "@/assets/svg/jobs.vue"
import ProzessIcon from "@/assets/svg/prozesse.vue"
import TimelineIcon from "@/assets/svg/timeline.vue"
import TodoIcon from "@/assets/svg/todos.vue"
import WikiIcon from "@/assets/svg/wiki.vue"

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const hasAccess = (level, abo = 0) => {
	return userStore.user.level >= level && userStore.user.plan >= abo
		? true
		: false
}

const mainItems = [
	{
		label: t('mainmenu.adressen'),
		url: "contacts",
		mm: "Contacts",
		icon: ContactIcon,
		access: LEVEL.STAFF,
	},
	{
		label: t('mainmenu.auftraege'),
		url: "jobs",
		mm: "Jobs",
		icon: JobIcon,
		access: LEVEL.MANAGER,
	},
	{
		label: t('mainmenu.dokus'),
		url: "dox",
		mm: "Dokus",
		icon: DokuIcon,
		access: LEVEL.CONTRIBUTOR,
		abo: ABO.PRO,
	},
	{
		label: t('mainmenu.timeline'),
		url: "timeline",
		mm: "Timeline",
		icon: TimelineIcon,
		access: LEVEL.STAFF,
		abo: ABO.STUDIO,
	},
	{
		label: t('mainmenu.todo'),
		url: "todos",
		mm: "Todos",
		icon: TodoIcon,
		access: LEVEL.CONTRIBUTOR,
		abo: ABO.TEAM,
	},
	{
		label: t('mainmenu.druck'),
		url: "procurements",
		mm: "Procurements",
		icon: DruckIcon,
		access: LEVEL.STAFF,
		abo: ABO.STUDIO,
	},
	{
		label: t('mainmenu.mail'),
		url: "mails",
		mm: "Mails",
		icon: BriefIcon,
		access: LEVEL.STAFF,
		abo: ABO.PRO,
	},
	{
		label: t('mainmenu.prozesse'),
		url: "processes",
		mm: "Processes",
		icon: ProzessIcon,
		access: LEVEL.CONTRIBUTOR,
		abo: ABO.STUDIO,
	},
	{
		label: t('mainmenu.wiki'),
		url: "wikis",
		mm: "Wiki",
		icon: WikiIcon,
		access: LEVEL.STAFF,
		abo: ABO.STUDIO,
	},
]

const accountItems = computed(() => {
	let items = []
	if (hasAccess(LEVEL.ADMIN)) {
		items = [
			{
				label: 'Reports',
				url: "account/reports",
				abo: 0,
			},
			{
				label: t("mainmenu.favoriten"),
				url: "account/favs",
				abo: 0,
			},
			{
				label: t("account.categories"),
				url: "account/cats",
				abo: 0,
			},
			{
				label: 'Team',
				url: "account/team",
				abo: ABO.TEAM,
			},
			{
				label: 'Account',
				url: "account",
				abo: 0,
			},
			{
				label: 'Import',
				url: "account/import",
				abo: 0,
			},
			{
				label: 'Export',
				url: "account/export",
				abo: 0,
			},
		]
	}
	items.push({
		label: 'Logout',
		url: "logout",
		abo: 0,
	})
	return items
})
</script>

<template>
	<nav>
		<router-link to="/" class="logo">
			<invers />
		</router-link>
		<ul class="main">
			<template v-for="(item, index) in mainItems" :key="index">
				<li v-if="hasAccess(item.access, item?.abo)" :class="{ current: route.meta.mm == item.mm }">
					<router-link :to="`/${item.url}`">
						<component :is="item.icon" />
						<span>{{ item.label }}</span>
					</router-link>
				</li>
			</template>
		</ul>
		<div class="user">
			<router-link to="/account/profile">
				<Avatar />
				<span>{{ userStore.user.displayname }}</span>
			</router-link>
			<ul class="account">
				<template v-for="(item, index) in accountItems" :key="index">
					<li v-if="userStore.user.plan >= item.abo">
						<router-link :to="`/${item.url}`">
							{{ item.label }}
						</router-link>
					</li>
				</template>
			</ul>
		</div>
	</nav>
</template>

<style lang="sass" scoped>
nav
	@apply fixed top-0 left-0 transition-all duration-300 ease-in-out py-2 grid gap-8 pb-8
	grid-template-rows: max-content 1fr max-content
	z-index: var(--z-menu)
	width: var(--mmschmal)
	height: 100vh
	background: linear-gradient(115deg, var(--blau) 40%, var(--dunkelblau) 100%)

	.logo
		@apply block px-1
		svg
			height: 42px
			width: 42px
			fill: white

	ul.main
		@apply mt-8 flex flex-col gap-2
		li
			@apply transition-all duration-300 ease-in-out py-3 px-2
			a
				@apply block text-white whitespace-nowrap tracking-wider transition-all duration-300 ease-in-out visited:text-white focus:text-white
				svg
					@apply block w-7 h-7
					fill: white
				span
					@apply hidden
			&:hover
				color: white
				background-color: var(--orange)
			&.current
				background-color: white
				color: var(--blau)
				svg
					fill: var(--blau)
				a
					span
						color: var(--blau)


	div.user
		@apply relative text-center transition-all duration-300 ease-in-out
		width: var(--mmschmal)
		& > a
			@apply text-white text-xs p-1 flex flex-col items-center overflow-hidden
			line-height: 2
			span
				@apply block w-full
				overflow: hidden
				text-overflow: ellipsis
				white-space: nowrap
		ul.account
			@apply hidden absolute text-left
			right: 0
			bottom: 0
			transform: translateX(100%)
			background-color: var(--blau)
			li
				@apply overflow-hidden text-white
				a
					@apply text-white px-4 py-2 block
					&:hover
						background-color: var(--orange)
		&:hover // user
			ul.account
				@apply block

	&:hover // nav:hover
		width: var(--mmwidth)
		div.user
			width: var(--mmwidth)
		ul.main
			li
				a
					@apply grid items-center
					grid-template-columns: 35px max-content
					span
						@apply block pt-1
</style>
