import { defineStore } from "pinia";

export const useAppStore = defineStore("AppStore", {
	state() {
		return {
			isFetching: false,
			isSaving: false,
			showNotification: false,
			apiResult: true,
			message: "",
		};
	},

	actions: {
		setMessage(message) {
			this.message = message;
			setTimeout(() => {
				this.message = "";
			}, 4000);
		},
		setFetching(val) {
			this.isFetching = val;
		},
		setApiResult(status) {
			this.apiResult = status;
			if (status >= 400) {
				this.showNotification = true;
				setTimeout(() => {
					this.showNotification = false;
				}, 4000);
			}
		},
	},
});
