import { createWebHistory, createRouter } from 'vue-router';
import { useUserStore } from '@/stores/user.js';
import { useItemStore } from "@/stores/item.js"
import { ABO, LEVEL } from '@/constants.js'

const routes = [
	{
		path: '/welcome',
		redirect: '/welcome/login',
		component: () => import('@/views/welcome'),
		meta: { mm: 'welcome', auth: false },
		children: [
			{
				path: 'login',
				component: () => import('@/components/welcome/login'),
			},
			{
				path: 'master',
				component: () => import('@/components/welcome/getmaster'),
			},
			// {
			// 	path: 'signup',
			// 	component: () => import('@/components/welcome/signup'),
			// },
			// {
			// 	path: 'confirm/:token',
			// 	component: () => import('@/components/welcome/confirm'),
			// },
			// {
			// 	path: 'showmaster',
			// 	component: () => import('@/components/welcome/showmaster'),
			// },
			// {
			// 	path: 'invitation/:token',
			// 	component: () => import('@/components/welcome/invite'),
			// },
			// {
			// 	path: 'request',
			// 	component: () => import('@/components/welcome/request'),
			// },
			// {
			// 	path: 'setpass/:id/:token',
			// 	component: () => import('@/components/welcome/setpass'),
			// },
		],
	},
	{
		path: '/',
		alias: '/dashboard',
		component: () => import('@/views/dashboard'),
		meta: { mm: 'Dashboard', auth: true, abo: ABO.STARTER, level: 0 },
	},
	{
		path: '/contacts',
		component: () => import('@/views/contacts'),
		meta: { mm: 'Contacts', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/contact/:id',
		component: () => import('@/components/contacts/Details.vue'),
		meta: { mm: 'Contacts', abo: ABO.STUDIO, level: LEVEL.CONTRIBUTOR },
	},
	{
		path: '/jobs',
		component: () => import('@/views/jobs'),
		meta: { mm: 'Jobs', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/job/:id',
		component: () => import('@/components/jobs/Details.vue'),
		meta: { mm: 'Jobs', abo: ABO.STUDIO, level: LEVEL.CONTRIBUTOR },
	},
	{
		path: '/dox',
		component: () => import('@/views/dokus'),
		meta: { mm: 'Dokus', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/doc/:id',
		component: () => import('@/components/dokus/Details.vue'),
		meta: { mm: 'Dokus', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/timeline',
		component: () => import('@/views/timelines'),
		meta: { mm: 'Timeline', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/timeline/:id',
		component: () => import('@/components/timeline/Timeline.vue'),
		meta: { mm: 'Timeline', abo: ABO.STUDIO, level: LEVEL.CONTRIBUTOR },
	},
	{
		path: '/todos',
		component: () => import('@/views/todos'),
		meta: { mm: 'Todos', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/mails',
		component: () => import('@/views/mails'),
		meta: { mm: 'Mails', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/mail/:id',
		component: () => import('@/components/mails/Details.vue'),
		meta: { mm: 'Mails', abo: ABO.STUDIO, level: LEVEL.CONTRIBUTOR },
	},
	{
		path: '/procurements',
		component: () => import('@/views/procurements'),
		meta: { mm: 'Procurements', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/procurement/:id',
		component: () => import('@/components/procurement/Details.vue'),
		meta: { mm: 'Procurements', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/processes',
		component: () => import('@/views/processes'),
		meta: { mm: 'Processes', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/process/:id',
		component: () => import('@/components/processes/Details.vue'),
		meta: { mm: 'Processes', abo: ABO.STUDIO, level: LEVEL.CONTRIBUTOR },
	},
	{
		path: '/wikis',
		component: () => import('@/views/wikis'),
		meta: { mm: 'Wiki', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/wiki/:id',
		component: () => import('@/components/wiki/Details.vue'),
		meta: { mm: 'Wiki', abo: ABO.STUDIO, level: LEVEL.STAFF },
	},
	{
		path: '/account',
		component: () => import('@/views/account'),
		children: [
			{
				path: '',
				meta: { mm: 'account', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Account'),
			},
			{
				path: 'favs',
				meta: { mm: 'favs', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Favs'),
			},
			{
				path: 'cats',
				meta: { mm: 'cats', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Kats'),
			},
			{
				path: 'profile',
				meta: { mm: 'profil', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Profil'),
			},
			{
				path: 'team',
				meta: { mm: 'team', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Team'),
			},
			{
				path: 'reports',
				meta: { mm: 'reports', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Reports'),
			},
			{
				path: 'import',
				meta: { mm: 'import', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Import'),
			},
			{
				path: 'export',
				meta: { mm: 'export', auth: true, abo: ABO.STUDIO, level: LEVEL.STAFF },
				component: () => import('@/components/account/Export'),
			},
		],
	},
	{
		path: '/logout',
		redirect: () => { return { path: '/welcome/login' } }
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: () => { return { path: '/welcome/login' } }
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to) => {
	const itemStore = useItemStore()
	itemStore.$reset()
	const userStore = useUserStore();
	if (to.meta?.auth !== false && !userStore.isAuthenticated) return '/login';
	if (to.meta?.level && to.meta.level > userStore.user.level) return '/dashboard';
});

export default router;
